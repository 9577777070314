export enum SignalAlertPerfTypeEnum {
    COMBO                  = 1, // combo report
    YESTERDAY              = 2, // yesterday rep
    APERF                  = 3, // good alert score
}

export class SignalAlertPerfTypeEnumHelper {
    protected static readonly namesMap: Map<SignalAlertPerfTypeEnum, string> = new Map([
        [SignalAlertPerfTypeEnum.COMBO,         'COMBO'],
        [SignalAlertPerfTypeEnum.YESTERDAY,     'YEST'],
        [SignalAlertPerfTypeEnum.APERF,          'APERF'],
    ]);

    public static names() {
        return this.namesMap;
    }
}
