import React, {useCallback, useContext, useEffect, useState} from 'react'
import {APIState} from '_constants/APIState';
import {
  Analysis,
  AnalysisCumulativeChartData,
  CumulativeChartLines, CumulativeStatsResult,
  DateEx,
  IQuote,
  IQuoteFull,
  LinePoints,
  PointUpDown,
  SignalsData,
  StockDirectionType as DirectionType,
  StockSignalsEnum,
  StockStatsIntervalEnum
} from 'predictagram-lib';
import {adminApiServiceCommon} from 'services/AdminApiService';
import {ChartModel as ChartOptions} from "../../../models/chart.model";
import {IChart} from "../../../interfaces/chart/IChart";
import {PredictionChart} from "../../public/ListingsPage/components/Prediction/PredictionChart";
import * as Plotly from "plotly.js";

import {ChartOptionsList, IAdminCumeChartProps,} from './helper';
import {EyeToggle} from 'components/common/EyeToggle';
import {coloredSignalNameMapper} from '../common/ColoredSignal';
import {Debug} from 'components/common/Debug';
import {predictionService} from 'services';
import {MessengerContext} from 'components/common/messenger';

export interface ChartLineBase {
  color: string /*| string[]*/,
  mode: 'lines'|'markers'|'lines+markers',
  lineMode?: any,
  markerSize?: number,
}

export interface ChartLine extends LinePoints,ChartLineBase  {
  isRender: boolean,
}

export const DEFAULT_BUY_COLOR = '#5fbf3b';
export const DEFAULT_SELL_COLOR = '#df3434';


type SelectedSignal = {
  signals: (d:AnalysisCumulativeChartData)=>PointUpDown[],
  color: string,
  type: StockSignalsEnum,
  isBaseSignal: boolean,
  isActive: boolean,
}


export const CumulativeChart: React.FunctionComponent<IAdminCumeChartProps> = ({
  cumeDataSearchOptions,
  params,
  setIsLoading
  }) => {

  const supResColors = [
    '#7873B066',
    'rgba(112,112,118,0.4)',
    'rgba(172,166,241,0.4)',
    'rgba(83,70,97,0.4)',
    'rgba(79,77,107,0.4)',
    'rgba(155,151,197,0.4)',
    'rgba(196,193,219,0.4)',
    'rgba(219,219,240,0.4)',
  ];
  const signalColors = [
    '#ffffff',
    '#ae4acd',
    '#75ffd3',
    '#ffd503',
    '#645906',
    '#18ac7f',
    '#af531a',
    '#cdff03',
    '#03ffb3',
    '#642a76',
    '#b8a339',
    '#36b366',
    '#615813',
  ];

  const msgrContext = useContext(MessengerContext);

  const [selectedSignals, setSelectedSignals] = useState<SelectedSignal[]>();

  const isRenderSignal = useCallback((signal: StockSignalsEnum) => {
    return !!selectedSignals?.find((v)=>v.type===signal);
  }, [selectedSignals]);


  const isRenderLine = useCallback((line: CumulativeChartLines) => {
    const showLines = params?.showLines;
    if (!showLines) return false;
    return showLines.includes(line);
  }, [params?.showLines]);

  const isOptionChecked = useCallback((optionName: ChartOptionsList): boolean => {
    // @TODO: remove conversion
    const options = params?.showOptions as any as ChartOptionsList[];
    if (!options) return false;
    return options.includes(optionName);
  }, [params?.showOptions]);

  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [chartData, setChartData] = useState<IChart | undefined>(undefined);
  const [macdChart, setMacdChart] = useState<IChart | undefined>(undefined);
  const [cumulativeData, setCumulativeData] = useState<AnalysisCumulativeChartData>({} as any);
  
  const [prices_5min, setPrices_5min] = useState<readonly IQuote[]>([]);


  // Generate list of all signals with colors
  useEffect(()=>{
    let renderedSignalsCount = 0;
    const getColor = ()=>{
        return signalColors[renderedSignalsCount++ % (signalColors.length - 1)];
    }

    const sgs:SelectedSignal[] = [];
    params?.showBaseSignals?.forEach((sigType)=>{
      sgs.push({
        color: getColor(),
        type: sigType,
        isActive: true,
        isBaseSignal: true,
        signals: (d)=>{
          if (sigType in d.cumulativeData.signals) {
            return (d.cumulativeData.signals as any)[sigType];
          }
          return []
        }
      });
    });
    cumeDataSearchOptions.openSignalSetup?.signals.forEach((sg,i)=>{
      sgs.push({
        color: getColor(),
        type: sg.type,
        isActive: true,
        isBaseSignal: false,
        signals: (d)=>d.cumulativeData.signalSetupResults?.[i]||[]
      });

    });
    setSelectedSignals(sgs);
  }, [params?.showBaseSignals, cumeDataSearchOptions]);

  const load = useCallback(async () => {
    try {
      setApiState(APIState.LOADING);

      setIsLoading && setIsLoading(true);

      // @TODO: refactor, need to send all params from input object
      // BUT the object includes entire analysis, with elements from strategy page
      const cumulData = await adminApiServiceCommon.analysisCumulativeChartData({
        startTime: cumeDataSearchOptions.startTimePredMade,
        endTime: cumeDataSearchOptions.endTimePredMade,
        stockSymbolId: cumeDataSearchOptions.stockSymbolId,
        extraSymbolIds: cumeDataSearchOptions.extraSymbolIds,
        predictionTypes: cumeDataSearchOptions.predictionTypes,
        userAverageScore: cumeDataSearchOptions.userAverageScore,
        openSignalSetup: cumeDataSearchOptions.openSignalSetup,
        predictionUserIds: cumeDataSearchOptions.predictionUserIds,
        predictionIds: cumeDataSearchOptions.predictionIds,
        endHourEst: cumeDataSearchOptions.endHourEst,
        startHourEst: cumeDataSearchOptions.startHourEst,
        predictionFirstMinStepSizeMoveMax: cumeDataSearchOptions.predictionFirstMinStepSizeMoveMax,
        inputOptions: cumeDataSearchOptions.inputOptions,
      } as Analysis.InputParamsEx);

      // 5-min dataset
      const symbols = await adminApiServiceCommon.getSymbols();
      const stockSymbol = symbols.find(v=> {
        return +v.id === +cumeDataSearchOptions.stockSymbolId
      })
      const prices_5min = await predictionService.getBarsData
        (stockSymbol?.name || '',
          new DateEx(cumeDataSearchOptions.startTimePredMade * 1000),
          new DateEx(cumeDataSearchOptions.endTimePredMade * 1000),
          true,
          StockStatsIntervalEnum.MIN_5);
      setPrices_5min(prices_5min);

      setApiState(APIState.LOADED);
      setCumulativeData(cumulData);
      setIsLoading && setIsLoading(false);

    } catch (error: any) {
      setApiState(APIState.ERROR);
      setIsLoading && setIsLoading(false);
      msgrContext.setMessage({body: `${error.message}`})
    }
  }, [cumeDataSearchOptions.endTimePredMade, cumeDataSearchOptions.extraSymbolIds, cumeDataSearchOptions.openSignalSetup, cumeDataSearchOptions.predictionTypes, cumeDataSearchOptions.startTimePredMade, cumeDataSearchOptions.stockSymbolId, cumeDataSearchOptions.userAverageScore, setIsLoading]);

  const getCumulLines = (predVals:LinePoints, predValsOthers: LinePoints, cumDataAll: CumulativeStatsResult)=>{
    const cumData = cumDataAll.cumulStats;
    const minMaxData = cumDataAll.minMaxStats;
    const isRenderMacd = isRenderLine(CumulativeChartLines.MACD);
    const cumulStats:{ [key: string]: ChartLine } = {
        predVals: { x: predVals.x, y: predVals.y, color: '#ED7014', isRender: isRenderLine(CumulativeChartLines.PREDICTION_VALUES), mode: 'markers' },
        predValsOthers: { x: predValsOthers.x, y: predValsOthers.y, color: '#edc214', isRender: isRenderLine(CumulativeChartLines.PREDICTION_VALUES), mode: 'markers' },
        last5predsAvg: { x: cumData.last5predsAvg.x, y: cumData.last5predsAvg.y, color: '#c0d6e4', isRender: isRenderLine(CumulativeChartLines.LAST_5PRED_AVG), mode: 'lines+markers' },
        last3predsAvg: { x: cumData.last3predsAvg.x, y: cumData.last3predsAvg.y, color: '#c39797', isRender: isRenderLine(CumulativeChartLines.LAST_3PRED_AVG), mode: 'lines+markers' },
        last15minAvg: { x: cumData.last15minAvg.x, y: cumData.last15minAvg.y, color: '#22AA99', isRender: isRenderLine(CumulativeChartLines.LAST_15MIN_AVG), mode: 'lines+markers' },
        last30minAvg: { x: cumData.last30minAvg.x, y: cumData.last30minAvg.y, color: '#AAAA11', isRender: isRenderLine(CumulativeChartLines.LAST_30MIN_AVG), mode: 'lines+markers' },
        last1hAvg: { x: cumData.last1hAvg.x, y: cumData.last1hAvg.y, color: '#59643f', isRender: isRenderLine(CumulativeChartLines.LAST_1H_AVG), mode: 'lines+markers' },
        last2hAvg: { x: cumData.last2hAvg.x, y: cumData.last2hAvg.y, color: '#88598f', isRender: isRenderLine(CumulativeChartLines.LAST_2H_AVG), mode: 'lines+markers' },
        last3hAvg: { x: cumData.last3hAvg.x, y: cumData.last3hAvg.y, color: '#aa6d11', isRender: isRenderLine(CumulativeChartLines.LAST_3H_AVG), mode: 'lines+markers' },
        last4hAvg: { x: cumData.last4hAvg.x, y: cumData.last4hAvg.y, color: '#757e98', isRender: isRenderLine(CumulativeChartLines.LAST_4H_AVG), mode: 'lines+markers' },

        // min/max 4h avg
        min4hAvg: { x: minMaxData.stats4hAvg.min.x, y: minMaxData.stats4hAvg.min.y, color: '#ce2093', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_4H), mode: 'lines', lineMode: 'dash' },
        max4hAvg: { x: minMaxData.stats4hAvg.max.x, y: minMaxData.stats4hAvg.max.y, color: '#56931b', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_4H), mode: 'lines', lineMode: 'dash' },
        min4h60Avg: { x: minMaxData.stats4h160pctAvg.min.x, y: minMaxData.stats4h160pctAvg.min.y, color: '#ce2093', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_4H), mode: 'lines' },
        max4h60Avg: { x: minMaxData.stats4h160pctAvg.max.x, y: minMaxData.stats4h160pctAvg.max.y, color: '#56931b', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_4H), mode: 'lines' },

        // min/max 4h avg
        min2hAvg: { x: minMaxData.stats2hAvg.min.x, y: minMaxData.stats2hAvg.min.y, color: '#92286e', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H), mode: 'lines', lineMode: 'dash' },
        max2hAvg: { x: minMaxData.stats2hAvg.max.x, y: minMaxData.stats2hAvg.max.y, color: '#30441d', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H), mode: 'lines', lineMode: 'dash' },
        min2h160pctAvg: { x: minMaxData.stats2h160pctAvg.min.x, y: minMaxData.stats2h160pctAvg.min.y, color: '#822f66', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H), mode: 'lines' },
        max2h160pctAvg: { x: minMaxData.stats2h160pctAvg.max.x, y: minMaxData.stats2h160pctAvg.max.y, color: '#3e6917', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H), mode: 'lines' },
        min2h220pctAvg: { x: minMaxData.stats2h220pctAvg.min.x, y: minMaxData.stats2h220pctAvg.min.y, color: '#d41818', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H), mode: 'lines' },
        max2h220pctAvg: { x: minMaxData.stats2h220pctAvg.max.x, y: minMaxData.stats2h220pctAvg.max.y, color: '#27ce12', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H), mode: 'lines' },

        // min/max ATR
        min2hVd30m160pct: { x: cumDataAll.minMaxStatsAtr.pct160.min.x, y: cumDataAll.minMaxStatsAtr.pct160.min.y, color: '#ea9fd1', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H_ATR), mode: 'lines' },
        max2hVd30m160pct: { x: cumDataAll.minMaxStatsAtr.pct160.max.x, y: cumDataAll.minMaxStatsAtr.pct160.max.y, color: '#6ec223', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H_ATR), mode: 'lines' },
        min2hVd30m220pct: { x: cumDataAll.minMaxStatsAtr.pct220.min.x, y: cumDataAll.minMaxStatsAtr.pct220.min.y, color: '#c58787', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H_ATR), mode: 'lines' },
        max2hVd30m220pct: { x: cumDataAll.minMaxStatsAtr.pct220.max.x, y: cumDataAll.minMaxStatsAtr.pct220.max.y, color: '#7da478', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H_ATR), mode: 'lines' },
        min2hVd30m400pct: { x: cumDataAll.minMaxStatsAtr.pct400.min.x, y: cumDataAll.minMaxStatsAtr.pct400.min.y, color: '#813b3b', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H_ATR), mode: 'lines' },
        max2hVd30m400pct: { x: cumDataAll.minMaxStatsAtr.pct400.max.x, y: cumDataAll.minMaxStatsAtr.pct400.max.y, color: '#416f3b', isRender: isRenderLine(CumulativeChartLines.MIN_MAX_2H_ATR), mode: 'lines' },

        ema6: { x: cumData.ema6.x, y: cumData.ema6.y, color: '#ccec76', isRender: isRenderMacd || isRenderLine(CumulativeChartLines.EMA6), mode: 'lines' },
        ema6day: { x: cumData.ema6day.x, y: cumData.ema6day.y, color: '#ccec76', isRender: isRenderSignal(StockSignalsEnum.SIGNALS_EMA6_CANDLE_DAY), mode: 'lines' },
        ema12: { x: cumData.ema12.x, y: cumData.ema12.y, color: '#536917', isRender: isRenderMacd || isRenderLine(CumulativeChartLines.EMA12), mode: 'lines' },
        ema12day: { x: cumData.ema12day.x, y: cumData.ema12day.y, color: '#536917', isRender: isRenderSignal(StockSignalsEnum.SIGNALS_EMA12_CANDLE_DAY), mode: 'lines' },
        ema26: { x: cumData.ema26.x, y: cumData.ema26.y, color: '#bb1f2f', isRender: isRenderMacd || isRenderLine(CumulativeChartLines.EMA26), mode: 'lines' },
        ema26day: { x: cumData.ema26day.x, y: cumData.ema26day.y, color: '#bb1f2f', isRender: isRenderSignal(StockSignalsEnum.SIGNALS_EMA26_CANDLE_DAY), mode: 'lines' },
        ema120: { x: cumData.ema120.x, y: cumData.ema120.y, color: '#6f2c33', isRender: isRenderLine(CumulativeChartLines.EMA120), mode: 'lines' },
        sma120: { x: cumData.sma120.x, y: cumData.sma120.y, color: '#79bbe3', isRender: isRenderLine(CumulativeChartLines.SMA120), mode: 'lines' },
        vwap: { x: cumData.vwap.x, y: cumData.vwap.y, color: '#1f60bb', isRender: isRenderLine(CumulativeChartLines.VWAP), mode: 'lines' },

        prevCloseLine: { x: cumData.prevCloseLine.x, y: cumData.prevCloseLine.y, color: '#F9DDDDFF', isRender: isRenderLine(CumulativeChartLines.EXTRA_SUP_RES_LINES), mode: 'lines', lineMode: 'dash' },
        openLine: { x: cumData.openLine.x, y: cumData.openLine.y, color: '#F9DDDDFF', isRender: isRenderLine(CumulativeChartLines.EXTRA_SUP_RES_LINES), mode: 'lines', lineMode: 'dash' },
    };
    const lines: Plotly.PlotData[] = [];
    for (const k in cumulStats) {
      const v = cumulStats[k];
      if (v.isRender) {
        const l = { x: v.x, y: v.y, mode: v.mode, name: k, line: { width: 1, dash: v.lineMode }, marker: { size: v?.markerSize || 4, color: v.color }, hoverinfo: 'none' } as Plotly.PlotData;
        // l.hoverinfo = 'y';
        lines.push(l);
      }

    }
    return lines;
  }

  function addExtraSupResLines(cumDataAll: CumulativeStatsResult, chart: IChart,
                     stats: { pred: Readonly<Analysis.Prediction>[]; stock: Readonly<IQuoteFull>[]; prevDayClose: IQuoteFull | null }) {
    let count = 0;
    // render horizontal lines
    // if (signalsSupRes1hStockChecked || supResLinesChecked) {
    if (selectedSignals?.find(v => v.type === StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK && v.isActive) || isRenderLine(
      CumulativeChartLines.SUP_RES_LINES)) {
      // console.debug({supResData});
      // Pivot is calculated with the formula P = (H+L+C)/3, where P=Pivot, H=High, L=Low and C=Close
      // To calculate Support / Resistance:
      // R1 = 2P - L, S1 = 2P - H
      // R2 = (P - S1) +R1, S2 = P - (R2 - S1)
      // const prevDayLines: SupResLine = SupResLinesData.calcSupResLines(supResData);
      // const currDayLines = SupResLinesData.hourlyLines(stats.stockStats as any);
      // // currDayLines.unshift(prevDayLines);
      // console.debug(currDayLines);
      // const start = options.startTimePredMade;
      const end = cumeDataSearchOptions.endTimePredMade;
      for (const l of cumDataAll.cumulStats.supResLines) {

        [l.r1, l.s1, l.r2, l.s2].forEach(v => {
          chart.layout.shapes.push({ // curr value horizontal line
            type: 'line', x0: l.tLast, y0: v, x1: end, y1: v, layer: "below",
            line: {color: supResColors[count % (supResColors.length - 1)], width: 2, dash: 'dash'}
          } as Partial<Plotly.Shape>);
        });
        count++;
      }
    }
    const firstP = stats.stock.length > 0 ? stats.stock[0] : null;
    if (firstP) {
      const lastIndex = stats.stock.length - 1;
      const lastP = stats.stock[lastIndex];

      if (isRenderLine(CumulativeChartLines.TREND_LINES)) {
        const data = SignalsData.calcTrendLineRegression(stats.stock);
        [data.high, data.low].forEach(v => {
          const y0 = v.y(0);
          const y1 = v.y(lastIndex);
          chart.layout.shapes.push({ // curr value horizontal line
            type: 'line',
            x0: firstP.t, y0: y0,
            x1: lastP.t, y1: y1, layer: "below",
            line: {color: '#ebc8f4', width: 1, dash: 'dash'}
          } as Partial<Plotly.Shape>);
        });
        count++;
      }
    }
  }

  const renderChart = useCallback(async (cumulativeData: AnalysisCumulativeChartData, prices_5min: readonly IQuote[]) => {

    const stats = cumulativeData.stats;

    const d_5min = ChartOptions.prebuildChartDay({
      stats: prices_5min as IQuoteFull[],
      endFillTimeSec: cumeDataSearchOptions.endTimePredMade,
      chartWidth: 400
    })
    const d = ChartOptions.prebuildChartDay({
      stats: stats.stock,
      endFillTimeSec: cumeDataSearchOptions.endTimePredMade,
      chartWidth: 400
    });

    const cumDataAll = cumulativeData.cumulativeData;
    const cumData = cumDataAll.cumulStats;
    const minMaxData = cumDataAll.minMaxStats;

    const yMin = cumDataAll.yMin;
    const yMax = cumDataAll.yMax;

    const predVals: LinePoints = { x: [], y: [] };
    const predValsOthers: LinePoints = { x: [], y: [] };
    for (const p of stats.pred) {
      const isOther = !!(p as any).fromStockSymbolId;
      const arr = isOther ? predValsOthers : predVals;
      arr.x.push(p.valueTime);
      arr.y.push(p.value);
    }

    const lines = getCumulLines(predVals, predValsOthers, cumDataAll);

    let chartYMin: number = null as any;
    let chartYMax: number = null as any;

    chartYMin = yMin ? Math.min(yMin, d.dataLowDay) : d.dataLowDay;
    chartYMax = yMax ? Math.max(yMax, d.dataHighDay) : d.dataHighDay;

    const cData = ChartOptions.plotData('main', d.plotLines.during, ChartOptions.plotLineColor);
    const candle1m = ChartOptions.plotCandleData('price', d.plotCandles.during, [ChartOptions.candlestickGreenMuted, ChartOptions.candlestickRedMuted]);
    const candle5m = ChartOptions.plotCandleData('5-min price', d_5min.plotCandles.during, [ChartOptions.candlestickBlue, ChartOptions.candlestickOrange]);


    ChartOptions.setCustomPlotStockInfo(cData);
    // cData.text = (cData.x as number[]).map((v:number,i)=>{
    //   return [`price: ${cData.y[i]}`,`time: ${toUsTime(v)}`].join('<br>');
    // });
    [candle1m, candle5m].forEach((v)=>ChartOptions.setCustomPlotCandleInfo(v));

    if (isOptionChecked(ChartOptionsList.SHOW_HOVER_INFO)) {
      // cData.hovertemplate = '<b>%{y}</b><br>%{customdata[0]}';
      // https://stackoverflow.com/questions/59057881/how-to-customize-hover-template-on-with-what-information-to-show

      (candle5m as Plotly.CandlestickData).hoverinfo = "text";
      cData.hoverinfo = 'text';
      (candle1m as Plotly.CandlestickData).hoverinfo = "text";
    }

    const plotData = isOptionChecked(ChartOptionsList.SHOW_AS_CANDLE) ? [candle1m] : [cData];
    const plot_5min = isOptionChecked(ChartOptionsList.SHOW_5M_PRICE_CANDLE) ? [candle5m] : [];

    const chart: IChart = ChartOptions.dailyChartTemplate({
      tickVals: d.tickVals, tickText: d.tickText, formatStr: ChartOptions.formatPriceStr(d.dataHighDay),
      yMin: chartYMin, yMax: chartYMax,
      plotData: [
        ...plot_5min,
        ...plotData,
        ...lines
      ],
      rangeBreaks: d.chartDataPlotlyXGaps, rangeBreaksLines: d.chartDatePlotlyXGapsLines
    }) as IChart;

    const renderSignals = (points: PointUpDown[], buyColor:string, sellColor:string) => {
      points.forEach(s => {
        chart.layout.annotations.push({
          text: '',
          showarrow: true,
          x: s.x,
          y: s.y,
          arrowcolor: s.isSellSignal ? sellColor : buyColor,
          arrowside: 'end',
          ax: s.x,
          axref: "x",
          ay: s.y + (s.isSellSignal ? 1 : -1) * (chartYMax - chartYMin) / 6,
          ayref: "y",
          startstandoff: 10,
        });
        if (s.price) {
          chart.layout.annotations.push({
            text: '',
            showarrow: true,
            x: s.x,
            y: s.price,
            arrowcolor: '#F00',
            arrowside: 'end',
            ax: s.x,
            axref: "x",
            ay: s.price + (s.isSellSignal ? 1 : -1) * (chartYMax - chartYMin) / 60,
            ayref: "y",
            // startstandoff: 10,
          });
        }
      });
      return buyColor;
    }

    selectedSignals?.forEach(s=>{
      if (s.isActive) {
        renderSignals(s.signals(cumulativeData), s.color, s.color);
      }
    });

    if (params?.extraSignals) {
      params.extraSignals.forEach((s) => {
        let y = s.y;
        if (!y) {
          const q = cumulativeData.stats.stock.filter(q => q.t === s.x)?.shift();
          if (!q) {
            return;
          }
          y = q.c;
        }
        const isSell = s.direction === DirectionType.DOWN;
        const color = s.color || (isSell ? DEFAULT_SELL_COLOR : DEFAULT_BUY_COLOR);
        renderSignals([{ y: y, x: s.x, isSellSignal: isSell }], color, color);
      });
    }

    addExtraSupResLines(cumDataAll, chart, stats);

    ChartOptions.setCumulativeChartOpts(chart);

    chart.layout.showlegend = isOptionChecked(ChartOptionsList.SHOW_LEGEND);
    // chart.layout.xaxis.hoverformat = ' ';
    // chart.layout.hoverlabel = {
    //   // bgcolor: "#fff",
    //   // bordercolor: '#fff',
    //   // font: 16,
    //   // font_family: "Rockwell"
    // };
    const macdChartParams = {
      tickVals: d.tickVals, tickText: d.tickText, formatStr: ChartOptions.formatPriceStr(d.dataHighDay),
      yMin: cumData.macd.yMin, yMax: cumData.macd.yMax,
      plotData: [
        { x: cumData.macd.x, y: cumData.macd.y, mode: 'lines', name: 'MACD', line: { width: 1 }, hoverinfo: 'none' } as any as Plotly.PlotData
      ],
    } as any;
    // console.debug({macdChartParams});
    const mChart: IChart = ChartOptions.dailyChartTemplate(macdChartParams);
    // macdChart.layout = Object.assign({}, chart.layout);
    mChart.divId = "mchart";
    mChart.layout.height = 200;
    mChart.layout.paper_bgcolor = 'rgb(0,0,0)';
    mChart.layout.showlegend = true;
    mChart.layout.legend = { x: 0, xanchor: 'left', y: 1 };

 

    setChartData(chart);
    setMacdChart(mChart);
  }, [cumeDataSearchOptions.endTimePredMade, isOptionChecked, isRenderLine, params?.extraSignals, selectedSignals]);

  useEffect(() => {
    load();
  }, [load])


  useEffect(() => {
    if (apiState === APIState.LOADED) {
      renderChart(cumulativeData, prices_5min);
    }
  }, [cumulativeData, renderChart, apiState, prices_5min]);

  return (
    <div className="admin-predictions">
<Debug><pre>{JSON.stringify({params, cumeDataSearchOptions}, null, 2)}</pre></Debug>
      <div className="my-3">
        <div className="bg-black text-white p-2 d-flex flex-row flex-wrap gap-3">
          {selectedSignals?.map((v, i) => {
            return <div key={i} className="d-flex flex-row gap-1">
              <EyeToggle onDisable={() => {v.isActive=false; setSelectedSignals(Array.from(selectedSignals)) }}
                onEnable={() => {v.isActive=true; setSelectedSignals(Array.from(selectedSignals)) }}
                value={true}
              />
              {coloredSignalNameMapper(v.type, i, v.color)}
            </div>
          })}
        </div>


        {apiState === APIState.LOADED && macdChart && isRenderLine(CumulativeChartLines.MACD) &&
          <>
            <div>&nbsp;</div>
            <div style={{ display: 'block', clear: 'both', }}><PredictionChart chartData={macdChart} /></div>
          </>
        }

        {chartData &&
          <div style={{ display: 'block', clear: 'both', }}>
            <div><PredictionChart chartData={chartData} /></div>
          </div>
        }
      </div>
    </div>
  )
}
